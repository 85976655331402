<script>
import api from '@/services/secureHttps';

export default {
    props: {
        websiteId: {
            type: Number,
            required: true,
        },
        pageId: {
            type: Number,
            required: true
        }
    },

    created() {
        console.log('jsonld modal created');
    },


    data() {
        return {
            jsonLd: null
        }
    },

    methods: {

        getJsonLd() {
            this.jsonLd = null;
            api.getJsonLd(this.websiteId, this.pageId)
                .then(response => {
                    console.log("get json ld: ", response);

                    if (response.status === 200) {
                        this.jsonLd = response.data;
                    }
                });
        },
        copyToClipboard() {
            var input = document.createElement("textarea");
            input.value = this.jsonLd;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            // removing textarea after copy
            input.remove();
        }
    }
}

</script>
    
<template>
    <div>
        <!-- Modal -->
        <div id="jsonldModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel" aria-hidden="true" class="modal">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">JSON-LD</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <pre>
                            {{jsonLd}}
                        </pre>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="copyToClipboard()">Copy JSON-LD</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal -->
    </div>
</template> 