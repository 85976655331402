<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MetaDataForm from "./meta-data-form.vue";
import api from '@/services/secureHttps';

export default {
    components: {
        Layout,
        PageHeader,
        MetaDataForm
    },
    props: {
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        metaDataId : {
            type: Number,
            required: true
        },
        featureId : {
            type: Number,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        showSavedSuccessMsg: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            title: "Meta Data",
            items: [],
            existingForm: {}
        };
    },

    created() {
        console.log('pageId: ', this.pageId);
        //this.websiteId = this.$route.params.websiteId;
        // this.pageId = this.$route.params.pageId;
        this.fetchMetaDataForm()
    },

    methods: {
        fetchMetaDataForm() {
            console.log('metaData:', this.metaData);


            api.getPageMetaData(this.websiteId, this.pageId, this.featureId)
                .then(response => {
                    console.log('response', response);

                    if (response.status === 404) {
                        console.log('404')
                        this.existingForm = { groups: [] };
                    } else {
                      
                        if (typeof (response.data.groups) !== 'undefined') {
                            this.existingForm = response.data;
                           // this.existingForm.groups = response.data.groups;
                        } else {
                            this.existingForm = { featureId: this.featureId, groups: [] };
                        }
                    }
                })
        }
    }
}
</script>
    
    <template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <h1>Editing {{path}} - {{existingForm.feature}}</h1>
                        <MetaDataForm 
                        :websiteId="websiteId" 
                        :webPageId="pageId" 
                        :featureId="featureId" 
                        :existingForm="existingForm"
                        :showDelete=true
                        :showSavedSuccessMsg="showSavedSuccessMsg" />
                    </div>
                </div>
            </div>
        </div>
        
    </Layout>
</template>