<script>
//import { ref } from "vue";
import api from '@/services/secureHttps';
import GroupContainer from '@/components/metadata/groupContainer.vue';
import JsonldModal from '@/components/metadata/jsonldModal.vue';

import Modal from 'bootstrap/js/dist/modal';
//import { toRaw } from 'vue';
//const formBuilder = ref();

export default {
    name: 'metaDataForm',

    emits: ['newElementAdded', 'addSubFeature'],

    components: {
        GroupContainer,
        JsonldModal
    },
    props: {
        websiteId: {
            type: Number,
            required: true,
        },
        webPageId: {
            type: Number,
            required: true,
        },
        featureId: {
            type: Number,
            required: true
        },
        existingForm: {
            type: Object,
            required: false
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: false
        },
        showSavedSuccessMsg: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        feature: function () {
            this.featureSummary = [];
            this.fetchMetaDataForm();
        },
        existingForm: function (newValue) {
            console.log("EXISITNG FORM CHANGED!!");
            this.currentForm = newValue;
            this.featureSummary = [];
        }
    },
    data() {
        return {
            page: {
                title: "Pickers",
                meta: [{ name: "description" }],
            },
            currentForm: { featureId: null, groups: [] },
            featureSummary: [],
            successfullySaved: this.showSavedSuccessMsg,
            isSaving: false
        }
    },

    methods: {

        submitAsDraft() {
            this.submitMetaDataForm('draft');
        },

        submitAsPublished() {
            this.submitMetaDataForm('published');
        },

        submitMetaDataForm(publishedStatus) {
            this.isSaving = true;
            this.successfullySaved = false;

            //console.log("e", e);
            var data = {};
            data.websiteId = this.websiteId;
            data.webpageId = this.webPageId;
            data.featureId = this.featureId;
            data.metaData = this.currentForm;
            data.status = publishedStatus;

            this.clearAllValidation();

            api.addWebPagMetaData(data)
                .then(response => {
                    this.featureSummary = [];

                    if (this.showDelete === false) {
                        this.$router.push({
                            name: "metadata-edit-page", params: {
                                websiteId: `${this.websiteId}`,
                                pageId: `${this.webPageId}`,
                                metaDataId: `${response.data.metaDataId}`,
                                featureId: `${this.featureId}`,
                                showSavedSuccessMsg: 'true'
                            }
                        });
                    } else {
                        this.successfullySaved = true;
                    }
                })
                .catch(err => {
                    var response = err.response;
                    if (response.status === 400) {
                        if ('undefined' !== typeof response.data && response.data.metaData !== 'undefined') {
                            if (response.data.metaData.validationResults !== 'undefined') {
                                console.log("validation errors found");
                                this.handleValidationErrors(response.data.metaData.validationResults);

                                var modalEl = document.getElementById("validationErrorsModal");
                                var modal = new Modal(modalEl);
                                modal.show();
                            }

                            if (typeof response.data.metaData.featureValidation !== 'undefined') {

                                this.featureSummary = response.data.metaData.featureValidation;
                                console.log('this.featureSummary:', this.featureSummary);
                            }
                        }
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },

        handleValidationErrors(validationResults) {
            for (let j = 0; j < validationResults.length; j++) {
                let validation = validationResults[j];
                this.$refs.formBuilder.updateValidation(validation);
            }
        },

        clearAllValidation() {
            console.log('meta-data-form clear val');
            this.featureSummary = [];
            this.$refs.formBuilder.clearValidation();
        },

        deleteMetaData() {
            console.log("deleting");
            this.isSaving = true;

            api.deleteMetaData(this.websiteId, this.webPageId)
                .then(response => {
                    console.log("response", response);
                    if (response.status >= 200 && response.status < 300) {
                        this.$router.back();
                    } else {
                        var modalEl = document.getElementById("deleteFailedModal");
                        var modal = new Modal(modalEl);
                        modal.show();
                    }
                }).catch((e) => {
                    console.log(e);
                    var modalEl = document.getElementById("deleteFailedModal");
                    var modal = new Modal(modalEl);
                    modal.show();
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },

        showJsonld() {
            console.log(this.$refs.jsonLdModal);

            this.$refs.jsonLdModal.getJsonLd();
            var modalEl = document.getElementById("jsonldModal");
            var modal = new Modal(modalEl);
            modal.show();

        },

        onNewElementAddded() {
            console.log('met-data-form reveid new element added')
            this.$emit('newElementAdded', this.currentForm);
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("meta data form onAddSubFeature", subFeature, rootElement);
            this.$emit("addSubFeature", subFeature, rootElement);
        }

    }
}

</script>

<template>
    <div>
    <div v-if="currentForm.introInformation" class="row">
        <div class="col">
            <div class="card card border card-border-info p-2">
                <h1>Description</h1>
                <div v-html="currentForm.introInformation" />
            </div> 
        </div>
    </div>
    <div class="row">
        <div class="col padding">
            <form id="medaDataForm" class=".needs-validation">

                <!-- end feature select -->
                <div id="form">
                    <GroupContainer :groups="currentForm.groups" ref="formBuilder" @groupElementAdded="onNewElementAddded"
                        @addSubFeature="onAddSubFeature">
                    </GroupContainer>
                </div>

                <!--start form summary-->
                <div v-show="typeof this.featureSummary !== 'undefined' && this.featureSummary !== null && this.featureSummary.length > 0"
                    class="card border card-border-danger">
                    <div class="card-header">
                        <h6 class="card-title mb-0">{{ currentForm.feature }} errors</h6>
                    </div>
                    <div class="card-body">

                        <div v-for="validation in featureSummary" :key="validation.id" class="text-muted mb-2">
                            <i class="ri-error-warning-line me-3 align-middle fs-16" style="color: #b44c36;"></i>
                            {{ validation.message }}
                        </div>
                    </div>
                </div>
                <!--end form summary-->

                <div class="row gy-4 pb-4">
                    <div class="d-flex align-items-end justify-content-end">
                        <div v-show="showDelete">
                            <button variant="secondary" type="button" v-on:click="showJsonld();"
                                class="btn btn-secondary btn-animation waves-effect waves-light float-right"
                                data-text="ShowJsonLd"><span>Show JSON-LD</span></button>
                        </div> &nbsp;
                        <div v-show=" showDelete ">
                            <button variant="secondary" type="button" v-on:click=" deleteMetaData(); "
                                class="btn btn-secondary btn-animation waves-effect waves-light float-right"
                                data-text="Delete"><span>Delete</span></button>
                        </div> &nbsp;
                        <!-- <div v-show="currentForm.groups.length > 0">
                            <button variant="primary"
                                class="btn btn-primary btn-animation waves-effect waves-light float-right"
                                data-text="Submit"><span>Submit</span></button>
                        </div>-->
                        <div v-show=" currentForm.groups.length > 0 ">
                            <div class="dropdown">
                                <button class="btn btn-primary dropdown-toggle" type="button" id="dorpDownSubmit"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Save
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dorpDownSubmit">
                                    <li><span class="dropdown-item" data-text="draft" @click=" submitAsDraft ">Save as
                                            draft</span></li>
                                    <li><span class="dropdown-item" data-text="publish" @click=" submitAsPublished ">Save and
                                            publish</span></li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="isSaving">
                            <div class="spinner-border text-primary" :style=" `width: ${width}rem; height: ${height}rem;` "
                                role="status">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="deleteFailedModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body text-center p-5">
                                <div class="mt-4">
                                    <h4 class="mb-3">Delete failed</h4>
                                    <p class="text-muted mb-4">The deleting of page meta data failed. Please try again.
                                    </p>
                                    <div class="hstack gap-2 justify-content-center"><button type="button"
                                            class="btn btn-light" data-bs-dismiss="modal">Close</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end delete failed modal -->

                <!-- begin validation Failed Modal -->
                <div class="modal fade" id="validationErrorsModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body text-center p-5">
                                <!-- <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a" style="width: 120px; height: 120px;">
                                </lord-icon>-->
                                <div class="mt-4">
                                    <h4 class="mb-3">Validation Failed</h4>
                                    <p class="text-muted mb-4">There are one or more validations erros. Please check your
                                        form and try again.
                                    </p>
                                    <div class="hstack gap-2 justify-content-center"><button type="button"
                                            class="btn btn-light" data-bs-dismiss="modal">Close</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end delete failed modal -->

                <div v-show=" successfullySaved " class="alert alert-success" role="alert">
                    Successfully saved.
                </div>

            </form>
            <!-- end form -->

        </div>
        <JsonldModal ref="jsonLdModal" :websiteId=" websiteId " :pageId=" webPageId "></JsonldModal>
    </div>
</div>
</template>

<style scoped>
.padding {
    padding: 1.5em;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2em;
}
</style>

